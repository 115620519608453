import { Modal } from 'ant-design-vue'
import { useI18n } from 'vue-i18n'
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage'

export function accountExpiredCheck(expiredTs) {
  if (getLocalStorage('hasShowAccountWarning') == 1) {
    return
  }
  if (!expiredTs || expiredTs <= 0) {
    return
  }

  if (typeof expiredTs === 'string') {
    // 如果是字串，將其轉換為數字
    expiredTs = parseInt(expiredTs, 10)
  }

  const { t } = useI18n()
  const { locale } = useI18n()

  // 定義當前時間的時間戳記
  let currentTs = Date.now()

  // 將當前時間加上 30 天的時間間隔
  let thirtyDaysLaterTs = currentTs + 30 * 24 * 60 * 60 * 1000

  // 判斷到期時間是否在 30 天後
  if (expiredTs > thirtyDaysLaterTs) {
    return
  }

  // 定義字串
  let str = t('common.accountExpiredMsgBody')

  // 將時間戳記轉換為日期物件
  let expirationDate = new Date(expiredTs)

  let replacedStr = ''
  if (locale.value === 'tw') {
    // 取得年、月、日
    let year = expirationDate.getFullYear()
    let month = expirationDate.getMonth() + 1 // 月份從0開始，需要加1
    let day = expirationDate.getDate()

    // 將字串中的年、月、日替換為時間戳記的值
    replacedStr = str
      .replace('%d', year)
      .replace('%d', month)
      .replace('%d', day)
  } else {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    let month = monthNames[expirationDate.getMonth()]
    let day = expirationDate.getDate()
    let year = expirationDate.getFullYear()
    replacedStr = str
      .replace('%s', month)
      .replace('%d', day)
      .replace('%d', year)
  }

  setLocalStorage('hasShowAccountWarning', 1)
  Modal.warning({
    title: t('common.accountExpiredMsgTitle'),
    // icon: createVNode(ExclamationCircleOutlined),
    content: replacedStr,
    okText: t('LM005.confirm'),
    //cancelText: '取消',
    centered: true,
  })
}

export function accountExpiredAndShowMsg(
  expiredTs,
  title,
  msg,
  locale,
  confirmTxt
) {
  if (!expiredTs || expiredTs <= 0) {
    return
  }

  if (typeof expiredTs === 'string') {
    // 如果是字串，將其轉換為數字
    expiredTs = parseInt(expiredTs, 10)
  }

  // 定義字串
  let str = msg

  // 將時間戳記轉換為日期物件
  let expirationDate = new Date(expiredTs)

  let replacedStr = ''
  if (locale === 'tw') {
    // 取得年、月、日
    let year = expirationDate.getFullYear()
    let month = expirationDate.getMonth() + 1 // 月份從0開始，需要加1
    let day = expirationDate.getDate()

    // 將字串中的年、月、日替換為時間戳記的值
    replacedStr = str
      .replace('%d', year)
      .replace('%d', month)
      .replace('%d', day)
  } else {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    let month = monthNames[expirationDate.getMonth()]
    let day = expirationDate.getDate()
    let year = expirationDate.getFullYear()
    replacedStr = str
      .replace('%s', month)
      .replace('%d', day)
      .replace('%d', year)
  }

  Modal.warning({
    title: title,
    // icon: createVNode(ExclamationCircleOutlined),
    content: replacedStr,
    okText: confirmTxt,
    //cancelText: '取消',
    centered: true,
  })
}
